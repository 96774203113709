import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <Seo title="Page Not Found" />
    <div className="aux-page text-center">
      <div className="container">
        <h1 className="display-4">Page Not Found</h1>
        <p className="lead mb-5">
          Sorry, the page you are looking for does not exist.
        </p>
        <Link to="/" className="btn btn-primary">
          Take Me Home
        </Link>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
